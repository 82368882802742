@import "../../media-mixin.scss";

.footer {
  display: flex;
  justify-content: center;
  gap: 28px;
  padding: 0 38px 30px;
  z-index: 9999;

  @include media("<=tablet") {
    gap: 20px;
    padding: 0 15px 30px;
    flex-direction: column-reverse;
    align-items: center;
  }

  .flex-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  a {
    text-decoration: underline;
  }

  .copyright {
    text-align: center;
  }
}
