@import "../../media-mixin.scss";

.thank-you {
  .main {
    padding: 70px 0 50px;

    @include media("<=desktop-lg") {
      padding-top: 50px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .page-header {
    font-size: 68px;

    @include media("<=desktop-lg") {
      font-size: 52px;
      line-height: 52px;
      text-align: center;
    }

    @include media("<=tablet-lg") {
      font-size: 48px;
    }
  }

  .latest-offers {
    margin-top: 80px;
    width: 100%;

    @include media("<=tablet-lg") {
      margin-top: 50px;
    }

    .offers-header {
      font-size: 28px;

      @include media("<=tablet-lg") {
        text-align: center;
        line-height: 28px;
      }
    }

    .offers-container {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      margin-top: 20px;

      @include media("<=tablet-lg") {
        flex-wrap: wrap;
        gap: 15px;
      }

      .offer-item {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 25%;
        display: flex;
        flex-direction: column;

        @include media("<=tablet-lg") {
          flex-basis: 45%;
        }

        .offer-item-header {
          margin: 12px 0 5px;
          font-size: 18px;
        }

        .price {
          margin-top: 30px;
          position: relative;

          @include media("<=tablet-lg") {
            margin-top: 25px;
          }

          .old-price {
            bottom: calc(100% + 5px);
            position: absolute;
            opacity: 0.8;

            @include media("<=tablet-lg") {
              font-size: 16px;
            }

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 1px;
              background-color: #261a8e;
            }
          }

          .discount {
            font-size: 26px;
            color: #4120ee;

            @include media("<=tablet-lg") {
              font-size: 22px;
            }
          }
        }
      }
    }
  }

  .referal {
    margin-top: 100px;
    width: 100%;

    @include media("<=tablet-lg") {
      margin-top: 60px;
    }

    .referal-header {
      font-size: 28px;

      @include media("<=tablet-lg") {
        text-align: center;
        line-height: 28px;
      }
    }

    .referal-container {
      margin-top: 5px;
      width: 100%;
    }

    .referal-stats {
      margin-top: 15px;
      margin-left: 90px;

      @include media("<=tablet-lg") {
        display: flex;
        flex-direction: column;

        @include media("<=tablet-lg") {
          margin-left: 55px;
        }
      }
    }
  }

  .redeem {
    margin-top: 100px;
    width: 100%;

    @include media("<=tablet-lg") {
      margin-top: 60px;
    }

    .redeem-text {
      text-align: center;
      font-size: 18px;

      a {
        color: #261a8e;
      }
    }
  }

  .save {
    margin-top: 10px;
    width: 80%;

    @include media("<=tablet-lg") {
      width: 100%;
    }

    .save-header {
      font-size: 16px;

      @include media("<=tablet-lg") {
        text-align: center;
        line-height: 28px;
      }

      a {
        color: #261a8e;
      }
    }
  }
}

.referal-awards {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  
  @include media("<=tablet-lg") {
    flex-wrap: wrap;
    gap: 20px;
  }

  .award-container {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
    display: flex;
    flex-direction: column;

    @include media("<=tablet-lg") {
      flex-basis: 45%;
    }

    .award-header {
      margin: 12px 0 5px;
      font-size: 18px;
    }
  }
}
