@import "../../media-mixin.scss";

.header {
    padding: 35px 38px 0;
    display: flex;
    justify-content: space-between;
  
    @include media("<=tablet") {
      padding: 24px 15px 0;
      justify-content: center;
    }
  }
  