@import "./media-mixin.scss";

body {
  margin: 0;
  font-family: "Maison Neue Book", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 22px;

  // ?
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #08061c;

  @include media("<=tablet-lg") {
    font-size: 15px;
  }
}

.main {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @include media("<=desktop-xlg") {
    max-width: 1080px;
  }
}

.image-wrapper {
  overflow: hidden;
  border-radius: 6px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

* {
  box-sizing: border-box;
}

.bold {
  font-family: "Maison Neue Bold";
}

p,
h1,
h2,
h3,
h4,
ul,
li,
ol {
  margin: 0;
  padding: 0;
}

input,
button {
  background-color: transparent;
  border: 0px solid transparent;
  outline: none;
}

a {
  text-decoration: none;
  color: #08061c;
}

@font-face {
  font-family: "Maison Neue Bold";
  src: url("./assets/fonts/Maison-Neue-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Maison Neue Book";
  font-weight: 400;
  src: url("./assets/fonts/Maison-Neue-Book.ttf") format("truetype");
}
