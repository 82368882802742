@import "../../media-mixin.scss";

$textPrimary: #08061c;

.preloadImages {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  right: -100%;
  height: 0px;
  width: 0px;
  overflow: hidden;
}

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.landing .main {
  max-height: 900px;
  height: 900px;
  position: relative;

  @include media("<=tablet-lg") {
    margin: 20px 15px 0;
    width: auto;
    max-height: unset;
  }

  @include media("<=desktop-lg") {
    margin: 20px 15px 0;
    min-width: calc(100vw - 40px);
    width: auto;
  }

  @media (max-height: 950px) and (min-width: 769px) {
    max-height: calc(100vh - 135px);
    height: calc(100vh - 135px);
  }

  .heading-container {
    z-index: 1;
    position: absolute;
    top: 34%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-height: 950px) and (min-width: 769px) {
      top: 28%;
    }

    @include media("<=tablet-lg") {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      flex-direction: column;
    }

    h2 {
      font-size: 100px;
      line-height: 100px;
      letter-spacing: -8.5px;
      font-family: "Maison Neue Bold";
      white-space: nowrap;

      @include media("<=desktop-xlg") {
        font-size: 78px;
        line-height: 78px;
        letter-spacing: -6px;
      }

      @include media("<=tablet-lg") {
        font-size: 64px;
        line-height: 64px;
        letter-spacing: -6px;
      }
    }

    .left-side {
      display: flex;
      flex-direction: column;

      @include media("<=tablet-lg") {
        z-index: 2;
      }

      @include media("<=phone-lg") {
        padding-bottom: 20px;
      }

      .dynamic-heading {
        line-height: 1em;

        @include media("<=tablet-lg") {
          padding-left: 200px;
        }

        @include media("<=phone-lg") {
          padding-left: 20px;
        }
      }

      .discount {
        color: $textPrimary;
        font-size: 40px;
        display: flex;
        align-items: center;
        letter-spacing: -2px;

        @include media("<=tablet-lg") {
          font-size: 28px;
          align-self: center;
        }

        .star-containeer {
          position: relative;
          color: #cac3fc;
          display: inline-block;
          margin: 0 5px;
          z-index: 4;

          .percent {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 1.2em;
            z-index: 2;
            color: white;
          }

          .discount-star {
            @include media("<=tablet-lg") {
              height: 104px;
              width: 104px;
            }

            @media (max-height: 950px) and (min-width: 769px) {
              height: 115px;
              width: 115px;
            }
          }
        }
      }
    }

    .arrow-container {
      display: flex;
      align-items: center;
      margin-top: 25px;
      flex-shrink: 1;
      flex-grow: 1;
      animation: 500ms ease-in-out infinite blink;

      & > img {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
      }

      @include media("<=tablet-lg") {
        margin-top: 135px;
        position: absolute;
        height: 140px;
        width: 100%;
        z-index: 1;

        & > img,
        & > svg {
          height: 100%;
          width: 100%;
          object-fit: fill;
        }
      }
    }

    .static-heading {
      padding-top: 120px;

      @include media("<=tablet-lg") {
        padding-top: 1px;
        padding-right: 220px;
        align-self: flex-end;
      }

      @include media("<=phone-lg") {
        padding-right: 20px;
      }
    }
  }

  .phone-container {
    z-index: 3;
    max-height: 100%;
    position: relative;

    @include media("<=tablet-lg") {
      margin-top: 280px;
      max-height: 460px;
      height: 460px;
    }

    @include media("<=phone-lg") {
      margin-top: 299px;
      max-height: 360px;
      height: 360px;
    }
  }

  .phone-image {
    z-index: 2;
    position: relative;
    height: 100%;
    max-height: 100%;
    animation: 1500ms cubic-bezier(0.8, 0.05, 0.33, 0.95) infinite bounce;
  }

  .background-rays {
    position: absolute;
    left: 50%;
    bottom: 0;
    max-height: 100%;
    height: 100%;
    transform: translate(-38%, 9%);
    -webkit-backface-visibility: hidden;

    @include media("<=tablet-lg") {
      max-width: 100vw;
      transform: translate(-41%, -9%);
    }

    @include media("<=phone-lg") {
      transform: translate(-41%, 10%);
    }

    @media (max-height: 950px) {
      transform: translate(calc(-50% + 11vh), -2vh);
    }

    @media (max-height: 950px) and (max-width: 768px) {
      transform: translate(-42%, -5%);
    }

    @media (max-height: 950px) and (max-width: 568px) {
      transform: translate(-41%, 10%);
    }

    & g > path:first-child {
      will-change: opacity;
      animation: 1500ms cubic-bezier(0.8, 0.05, 0.33, 0.95) infinite bounceXRays;
    }
    & g > path:last-child {
      will-change: opacity;
      animation: 1500ms cubic-bezier(0.8, 0.05, 0.33, 0.95) infinite bounceRays;
    }
  }
}

.backstock-info {
  max-width: 420px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;

  @include media("<=tablet-lg") {
    align-items: center;
    text-align: center;
  }

  .quotes {
    height: 43px;
    width: 56px;

    @include media("<=tablet-lg") {
      height: 28px;
      width: 36px;
    }
  }

  h4 {
    margin-top: 11px;
  }
}

.absolute-container {
  position: absolute;
  bottom: 5vh;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 43px;

  @include media("<=tablet-lg") {
    bottom: 20px;
    margin-top: 100px;
    position: relative;
    flex-direction: column-reverse;
  }

  @media (max-height: 950px) and (min-width: 769px) {
    gap: 25px;
  }
}

.cta-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  z-index: 4;

  @include media("<=tablet-lg") {
    position: absolute;
    bottom: calc(100% + 10px);
  }

  .cta-info {
    color: $textPrimary;
    opacity: 0.5;
    text-align: end;

    @include media("<=tablet-lg") {
      text-align: center;
      align-self: center;
    }
  }
}

.hide-desktop {
  @include media(">tablet-lg") {
    display: none;
  }
}

.hide-mobile {
  @include media("<=tablet-lg") {
    display: none;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes bounceXRays {
  0% {
    opacity: 0.5;
    d: path("M340.775 663.798 0 0h1422L590.38 707.742l-1 4.264z");
  }
  50% {
    opacity: 1;
    d: path("M340.775 663.798 0 0h1422L590.38 737.742l-1 4.264z");
  }
  100% {
    opacity: 0.5;
    d: path("M340.775 663.798 0 0h1422L590.38 707.742l-1 4.264z");
  }
}

@keyframes bounceRays {
  0% {
    opacity: 0.5;
    d: path("M115.224 559.353 0 0l741.448 102.044L364.83 613.296l-3 4.265z");
  }
  50% {
    opacity: 1;
    d: path("M115.224 589.353 0 0l741.448 102.044L364.83 663.296l-3 4.265z");
  }
  100% {
    opacity: 0.5;
    d: path("M115.224 559.353 0 0l741.448 102.044L364.83 613.296l-3 4.265z");
  }
}
