@import "../../media-mixin.scss";

.email-error {
  position: absolute;
  top: calc(100% + 5px);
  left: 90px;
  color: red;
  font-size: 16px;

  @include media("<=tablet") {
    left: 55px;
  }
}

.cta-email-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  border-radius: 40px;
  border: 1px solid rgba(17, 13, 60, 0.3);
  box-shadow: 0 26px 26px 0 rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding-left: 90px;
  transition: 400ms ease-in-out;

  @include media("<=tablet") {
    height: 60px;
    padding-left: 55px;
    border-radius: 30px;
  }

  &.invalid {
    border: 1px solid rgba(212, 7, 7, 0.7);
    box-shadow: 0 16px 20px 0 rgba(212, 7, 7, 0.4);
  }

  .mail-svg {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    height: 30px;
    width: 36px;

    @include media("<=tablet") {
      left: 20px;
      height: 22px;
      width: 26px;
    }

    & > * {
      fill: #261a8e;
    }
  }

  .cta-input {
    font-size: 22px;
    color: #08061c;
    flex-grow: 1;
    padding: 20px 0;

    @include media("<=tablet") {
      font-size: 18px;
      flex-basis: 150px;
      width: 150px;
    }
  }

  .cta-submit {
    position: relative;
    background-color: #261a8e;
    height: 100%;
    border-radius: 40px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    white-space: nowrap;
    transition: all 400ms ease-in-out;
    padding: 0;
    display: flex;
    flex-basis: 380px;
    flex-shrink: 1;
    margin-left: 5px;

    .animation-container {
      border-radius: 40px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: relative;
   
      @include media("<=tablet") {
        font-size: 18px;
        padding: 0 10px;
      }
      
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 0px;
        width: 0px;
        display: block;
        opacity: 0;
        border-radius: 50%;
        box-shadow: 0 0 10px 12px #90e1a4;
        background-color: #90e1a4;
      }

      & > span {
        z-index: 2;
        position: relative;
      }
    }

    &.success .animation-container {
      &::before {
        animation: bubbleSuccess 800ms cubic-bezier(.56,.22,.43,.81);
      }
    }

    .cta-arrow {
      position: absolute;
      left: 50%;
      bottom: calc(100% + 20px);
      transform: translateX(-10%);
      height: 94px;
      width: 123px;
    }
  }
}

@keyframes bubbleSuccess {
  0% {
    height: 0px;
    width: 0px;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  45% {
    height: 400px;
    width: 400px;
  }
  60% {
    opacity: 1;
  }
  100% {
    height: 400px;
    width: 400px;
    opacity: 0;
  }
}
