@import "../../media-mixin.scss";

.privacy-policy {
  .main {
    align-items: normal;
    padding: 70px 0 50px;
    .page-header {
        text-align: center;
    }
    > * {
        margin-bottom: 15px;
    }
    ul  {
        padding-left: 30px;
    }
    @include media("<=desktop-lg") {
      padding-top: 50px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .page-header {
    font-size: 68px;
    margin-bottom: 50px;

    @include media("<=desktop-lg") {
      font-size: 52px;
      line-height: 52px;
      text-align: center;
    }

    @include media("<=tablet-lg") {
      font-size: 48px;
    }
  }
}