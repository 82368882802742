.links-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  
    .social-link {
      padding: 5px;
      border-radius: 50%;
      height: 28px;
  
      &:hover {
        transition: transform 400ms ease-in-out;
        transform: scale(1.1);
      }
    }
  }
  